import { Component, ComponentRef, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FilterItem, IAction } from '@shared/classes';
import { LinkedRelationItem } from '@shared/classes/model/backend/audit/model/linkedRelationItem';
import { BaseRequestControllerService, ToastService } from '@shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-form-submit-popup',
  templateUrl: './form-submit-popup.component.html',
  styleUrl: './form-submit-popup.component.scss',
})
export class FormSubmitPopupComponent implements OnInit {
  viewForm: Type<any>;
  component: ComponentRef<any>;
  dataService: BaseRequestControllerService<any>;
  filters: FilterItem[] = [];
  selectedRows: any[] = [];
  patchData: boolean = true;
  formData: any = {};
  linkedManualRelations: LinkedRelationItem[];
  options: any;
  submitFormAction: IAction = {
    id: 3,
    label: 'Save Form',
    buttonType: 'button',
    command: this.onFormSubmit.bind(this),
    icon: 'pi pi-save',
    // status$: this.formValid$,
  };
  proceedAction: IAction = {
    id: 2,
    label: 'Proceed without changes',
    buttonType: 'button',
    command: this.onProceed.bind(this),
    icon: 'pi pi-play',
    // status$: this.formValid$,
  };
  cancelButtonAction: IAction = {
    id: 1,
    label: 'Cancel',
    buttonType: 'button',
    command: this.onCancel.bind(this),
    // icon: "pi pi-times",
    color: 'secondary',
    // buttonStyle: 'outlined'
  };
  constructor(
    public viewContainerRef: ViewContainerRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public toast: ToastService
  ) {
    // this.viewForm = this.config?.data?.dynamicViewComponent;
    // this.dataService = this.config?.data?.dataService;
    // this.filters = [...this.config?.data?.filters];
    // this.selectedRows = this.config?.data?.selectedRows;
    // this.patchData = this.config?.data?.patchData ?? this.patchData;
    // this.formData = this.config?.data?.formData ?? this.formData;
    // this.options = this.config?.data?.options ?? this.options;
    // this.linkedManualRelations = this.config?.data?.linkedManualRelations ?? this.linkedManualRelations;
  }

  ngOnInit(): void {}

  onFormSubmit() {
    this.ref.close(IFormSubmitPopupStatus.SUBMIT_FORM);
  }
  onProceed() {
    this.ref.close(IFormSubmitPopupStatus.PROCEED);
  }
  onCancel() {
    this.ref.close(IFormSubmitPopupStatus.CANCEL);
  }
}
export enum IFormSubmitPopupStatus {
  PROCEED = 'PROCEED',
  SUBMIT_FORM = 'SUBMIT_FORM',
  CANCEL = 'CANCEL',
}
