<div class="w-full flex flex-column gap-2">
  <div class="flex flex-column align-items-center w-full gap-3">
    <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
    <p>{{ 'general.messages.Proceeding Will Drop Changes' | translate }}</p>
  </div>
  <div class="w-full flex flex-row-reverse gap-2">
    <app-button [action]="submitFormAction"></app-button>
    <app-button [action]="proceedAction"></app-button>
    <app-button [action]="cancelButtonAction"></app-button>
  </div>
</div>
