<div class="timeline-container">
  <div
    class="timeline-line"
    [style.width]="'calc(' + timelineWidth + '% - 60px)'"
    [pTooltip]="tooltipContent"
    tooltipPosition="top"
  >
    <div class="progress-bg" [style.width]="'calc(100% - 5px)'"></div>
    <div class="progress" [style.width]="'min(calc(100% - 5px), ' + cachedProgress + '%)'"></div>
    @for (event of events; track event; let i = $index) {
      <div
        class="timeline-event"
        [class.first-timeline-event]="i == 0"
        [class.last-timeline-event]="i == events?.length - 1"
        [style.left]="isRtl ? 'none' : i == 0 ? getFirstEventPosition() : getPosition(event.date, i)"
        [style.right]="isRtl ? (i == 0 ? getFirstEventPosition() : getPosition(event.date, i)) : 'none'"
      >
        @if (event?.newAssignee?.name) {
          <app-user-list-view
            [class.first-icon]="i == 0"
            [class.last-icon]="i == events?.length - 1"
            class="icon"
            [data]="event?.newAssignee?.name"
            [showName]="false"
            [grayColor]="!eventCrossedStatus[i]"
          ></app-user-list-view>
        } @else {
          <div class="icon pi pi-circle-fill"></div>
        }
        <!-- <div class="date">{{ event.date | date: 'MMM dd, yyyy' }}</div> -->
        @if (i == 0) {
          <div class="" #firstDate>
            <!-- <span class="">Assigned </span> -->
            <app-date-view
              class="date"
              [useTimeAgo]="true"
              [data]="event?.date"
              style="text-wrap: nowrap"
            ></app-date-view>
          </div>
        } @else {
          <app-date-view
            class="date"
            [useTimeAgo]="true"
            [data]="event?.date"
            style="text-wrap: nowrap"
          ></app-date-view>
        }
      </div>
    }
  </div>
</div>
<ng-template #tooltipContent>
  @if (nextUpcomingEventDate) {
    <div class="flex align-items-center gap-2">
      <span class="text-500">{{ 'general.messages.NextEventAt' | translate }}: </span>
      <app-date-view [useTimeAgo]="true" [data]="nextUpcomingEventDate" style="text-wrap: nowrap"></app-date-view>
    </div>
  } @else {
    {{ 'general.messages.NoFurtherEvents' | translate }}
  }
</ng-template>
