import { Component, Input, OnInit } from '@angular/core';

import {
  BaseForm,
  EntityTypeFieldDto,
  IssueTypeDto,
  ModuleKeywords,
  PermissionActions,
  TypesWithName,
  getEnumOptions,
  isNullObj,
} from '@shared/classes';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Issue } from '@shared/classes/model/backend/issue';
import { PathResolverService } from '@shared/services';
import { ViewModeService } from '@shared/services/view-mode.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { IssuesDataService } from '../../services/data/issues-data.service';

@Component({
  selector: 'app-issues-item-form',
  templateUrl: './issues-item-form.component.html',
  styleUrls: ['./issues-item-form.component.scss'],
})
export class IssuesItemFormComponent extends BaseForm<Issue> implements OnInit {
  targetTypes = []; // getEnumOptions(TargetTypeEnum);
  listOfStatus = getEnumOptions(Issue.IssueStatusEnum);
  minDate = new Date();

  listOfState = getEnumOptions(Issue.StateEnum);

  listOfCriticality = getEnumOptions(Issue.CriticalityEnum);

  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];

  @Input()
  itemId: string;

  @Input()
  searchT: string = null;

  @Input()
  editMode: Boolean = false;
  @Input()
  showCancelButton: Boolean = false;
  selectedTypedToInclude1 = [];
  selectedTypedToInclude2 = [];
  today = new Date();
  lockResolutionDate: boolean = false;
  constructor(
    private requestService: IssuesDataService,

    public viewModeService: ViewModeService,
    private permissionService: NgxPermissionsService,
    private pathResolverService: PathResolverService
  ) {
    super(viewModeService, ModuleKeywords.Issue);
  }
  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;

    // this.formGroup?.controls?.sourceType.valueChanges.subscribe((data) => {
    //   this.formGroup?.controls?.sourceCode.setValue(null);
    // });
  }
  ngOnInit(): void {
    if (this.popupOptions?.fromPopupData) {
      this.patchFormData({ ...this.data, ...this.popupOptions?.fromPopupData });
      this.data = { ...this.data, ...this.popupOptions?.fromPopupData };
    }
    this.today = new Date();
    const perms = this.permissionService.getPermissions();
    const allowedTypeList = [];
    getEnumOptions(TypesWithName).forEach((element) => {
      if (!isNullObj(perms?.[`${PermissionActions.Read}${element.value}`])) {
        allowedTypeList.push(element);
      }
    });
    this.targetTypes = [...allowedTypeList];
    this.formGroup.controls.causeItemsTypes.valueChanges.subscribe((types) => {
      this.selectedTypedToInclude1 = types;
      const codes = [];
      this.formGroup.controls?.causeItems?.value?.forEach((element) => {
        const module = this.pathResolverService.getModuleKeywordByCode(element);
        if (types?.includes(module)) {
          codes.push(element);
        }
      });
      this.formGroup.controls.causeItems.patchValue(codes);
    });
    this.formGroup.controls.effectItemsTypes.valueChanges.subscribe((types) => {
      this.selectedTypedToInclude2 = types;
      const codes = [];
      this.formGroup.controls?.effectItems?.value?.forEach((element) => {
        const module = this.pathResolverService.getModuleKeywordByCode(element);
        if (types?.includes(module)) {
          codes.push(element);
        }
      });
      this.formGroup.controls.effectItems.patchValue(codes);
    });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      assignee: new FormControl(null, Validators.required),
      criticality: new FormControl(null, Validators.required),
      issueOwner: new FormControl(null, Validators.required),
      parent: new FormControl(null),
      issueTypeCode: new FormControl(null, Validators.required),
      // sourceType: new FormControl(null),
      // sourceCode: new FormControl(null),
      // resolutionDate: new FormControl(new Date()),
      causeItems: new FormControl(null),
      effectItems: new FormControl(null),
      causeItemsTypes: new FormControl(null),
      effectItemsTypes: new FormControl(null),
      estimatedResolutionDate: new FormControl(this.today, Validators.required),
      actualResolutionDate: new FormControl(null),
      creationToResolutionDays: new FormControl(null),
      resolutionDelayDays: new FormControl(null),
      lastEscalation: new FormControl(null),
      lastEscalationDate: new FormControl(null),
      lastEscalationOrder: new FormControl(null),
      escalations: new FormControl(null),
      escalationsArchiveItems: new FormControl(null),
    });
  }
  onIssueTypeChanges(event: IssueTypeDto) {
    console.log(event);
    let futureDate = new Date();
    if (event?.resolutionDays) {
      // Add days
      futureDate.setDate(this.today.getDate() + event?.resolutionDays);
    }
    this.formGroup.controls?.estimatedResolutionDate?.patchValue(futureDate);
    this.lockResolutionDate = !!event?.lockResolutionDate;
  }
}
